<template>
  <div class="ss-model">
    <section class="block">
      <div class="icon">2</div>
      <span>模块2</span>
      <span class="text">最多支持增加 10 个模块</span>
    </section>
    <ManageItem v-for="(item, index) in list" :key="index" title="增加商品链接">
      <i v-if="index !== 0" class="el-icon-delete" @click="del(index)"></i>
      <el-input v-model="item.good_url" placeholder="请输入商品链接" @blur="getId(item)"></el-input>
      <img v-if="item.img" class="goods-img" :src="item.img" />
      <p v-if="item.title" class="goods-title">{{item.title}}</p>
      <p v-if="item.price" class="goods-price">{{`${item.price} 日元`}}</p>
    </ManageItem>
    <el-button v-if="list.length < 10" type="text" @click="add">+新增</el-button>
  </div>
</template>
<script>
import ManageItem from "@/components/commonUI/ManageItem";
import { defineComponent, reactive } from "@vue/composition-api";

export default defineComponent({
  components: { ManageItem },
  props: {
    goodsList: { type: Array, default: () => [] }
  },
  setup(props, { root }) {
    const list = reactive(props.goodsList)
    const add = () => {
      list.push({ price: '', title: '', img: '', good_url: '' })
    }
    const getId = (item) => {
      if(!item.good_url || !item.good_url.trim()) return
      root.$axios.post('/cms/topic/goods/search_goods', {
        goods_url: item.good_url
      })
      .then(res=>{
        getInfo(res.data.productDetails, item)
      })
    }
    const getInfo = (data, item) => {
      root.$axios.post('/cms/topic/goods/get_goods_detail', {
        goods_id: data.id,
        site: data.site
      })
      .then(res=>{
        item.img = res.data.images[0]
        item.title = res.data.name
        item.price = res.data.yenPrice
        item.goods_id = data.id
        item.site_id = data.siteId
        item.site_name = data.site
      })
    }
    const del = async (index) => {
      const confirm = await root.$goDialog('确定要删除吗？')
      if(confirm) {
        list.splice(index, 1)
      }
    }
    // watch(list,data=>{
    //   data.forEach(item=>{
    //     getId(item)
    //   })
    // },{deep:true})
    return {
      list,
      getId,
      del,
      add
    }
  },
});
</script>
<style lang="scss" scoped>
.icon {
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  background-color: #a7abbd;
  color: #fff;
  border-radius: 100%;
  text-align: center;
}
.block {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #9195a2;
  .icon {
    margin-right: 12px;
  }
  .text {
    color: #999;
    font-size: 12px;
    margin-left: 10px;
  }
}
.manage-box-item {
  background-color: #fafafa;
  padding: 16px 18px 26px 20px;
  position: relative;
  .el-icon-delete{
    position: absolute;
    right: 19px;
    top: 17px;
    font-size: 14px;
    cursor: pointer;
  }
}
.goods-img {
  margin-top: 24px;
  width: 86px;
  height: 86px;
  object-fit: contain;
  display: block;
}
.goods-title {
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  line-height: 14px;
  margin-top: 10px;
}
.goods-price {
  font-size: 12px;
  font-weight: 400;
  color: #ff6a5b;
  line-height: 14px;
  margin-top: 6px;
}
</style>