<template>
  <div class="ss-manage" v-if="show">
    <div class="ss-manage-box">
      <i class="close el-icon-close" @click="close(false)"></i>
      <h3 class="title">商品专题编辑</h3>
      <section class="ss-manage-box-content">
        <div class="ss-manage-box-left">
          <div class="ss-manage-box-preview">
            <div class="preview-content"><div class="icon">1</div></div>
            <section class="preview-subcontent">
              <div><div class="icon">2</div></div>
              <div></div>
              <div></div>
            </section>
          </div>
          <section class="block">
            <div class="icon">1</div>
            模块1
          </section>
          <ManageItem
            title="上传图片"
            color="#f14f46"
            word="推荐上传尺寸为：1380X740 px"
          >
            <upload :info="main_topic" class="upload-img" @getimage="getImage" />
          </ManageItem>
          <ManageItem
            title="首页标题"
            color="#f14f46"
            word="1-30个字符（中文算两个字符），不可为空"
          >
            <el-input v-model="main_topic.title" placeholder="请输入首页标题"></el-input>
          </ManageItem>
          <ManageItem title="跳转">
            <el-select v-model="main_topic.redirect_type">
              <el-option :value="1" label="APP内跳转"></el-option>
              <el-option :value="2" label="搜索页"></el-option>
            </el-select>
          </ManageItem>
          <ManageItem v-if="main_topic.redirect_type == 1" title="跳转链接">
            <el-input v-model="main_topic.redirect_url_inner_app" placeholder="请输入跳转链接"></el-input>
          </ManageItem>
          <ManageItem v-if="main_topic.redirect_type == 2" title="站点">
            <el-select v-model="main_topic.redirect_site" placeholder="请选择站点" multiple @change="getCategory(0,1)">
              <el-option v-for="item in siteList" :key="item.site_id" :value="item.site_id" :label="item.proxy_name"></el-option>
            </el-select>
          </ManageItem>
          <ManageItem  v-if="main_topic.redirect_type == 2" title="站内分类">
            <el-select v-model="category.first" @change="getCategory(category.first,2)">
              <el-option v-for="item in firstList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
            </el-select>
            <el-select v-if="category.first" v-model="category.second" @change="getCategory(category.second,3)">
              <el-option v-for="item in secondList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
            </el-select>
            <el-select v-if="category.second" v-model="category.third">
              <el-option v-for="item in thirdList" :key="item.category_platform_product_id" :value="item.category_platform_product_id" :label="item.name"></el-option>
            </el-select>
          </ManageItem>
          <ManageItem v-if="main_topic.redirect_type == 2" title="搜索文本">
            <el-input v-model="main_topic.search_text" placeholder="请输入搜索文本"></el-input>
          </ManageItem>
          <ManageItem title="副标题">
            <el-input v-model="main_topic.subtitle" placeholder="请输入副标题"></el-input>
          </ManageItem>
          <ManageItem title="说明">
            <el-input v-model="main_topic.explanation" placeholder="请输入说明"></el-input>
          </ManageItem>
        </div>
        <div class="ss-manage-box-right">
          <model :goods-list="sub_topic"></model>
        </div>
      </section>
      <footer class="ss-manage-footer">
        <el-button @click="close(false)">取消</el-button>
        <el-button type="primary" @click="debounce(confirm, 1000)">确认</el-button>
      </footer>
    </div>
  </div>
</template>
<script>
import Upload from "@/components/common/Upload";
import Model from "@/components/indexManager/specialSubject/Model";
import ManageItem from "@/components/commonUI/ManageItem";
import { defineComponent, reactive, ref, watch } from "@vue/composition-api";

export default defineComponent({
  components: { Upload, ManageItem, Model },
  model: {
    prop: "show",
    event: "change",
  },
  props: {
    show: { type: Boolean, default: false },
    info: { type: Object, default: () => {} },
  },
  setup(props, { emit, root }) {
    const main_topic = reactive({
      title: '',
      img: '',
      subtitle: '',
      explanation: '',
      id: undefined,
      url: '',
      redirect_type: 1,
      redirect_site: '',
      search_text: '',
      redirect_url_inner_app: '',
      redirect_platform_category: '',
    })
    const sub_topic = ref([])
    const siteList = ref([])
    const firstList = ref([])
    const secondList = ref([])
    const thirdList = ref([])
    const category = reactive({
      first: '',
      second: '',
      third: '',
    })
    watch(props.info, (val) => {
      Object.assign(main_topic, reactive(val.data.main_topic))
      main_topic.url = main_topic.img
      sub_topic.value = val.data.sub_topic
      if(main_topic.redirect_type == 2) {
        getCategory(0,1)
      }
    });
    watch(()=>category.first,()=>{
      secondList.value = []
      thirdList.value = []
      category.second = ''
      category.third = ''
      main_topic.redirect_platform_category = ''
    })
    watch(()=>category.second,()=>{
      thirdList.value = []
      category.third = ''
      main_topic.redirect_platform_category = ''
    })
    const getSite = () => {
      root.$axios.get(`/common/business/site/getSimplifyList`)
      .then((res) => {
        siteList.value = res.data;
      })
      
    }
    getSite()
    const getCategory = (id,type) => {
      root.$axios.get(`/common/business/PlatformCategory/getList`,{
        params: {
          p_id: id
        }
      })
      .then(res=>{
        main_topic.children_category_id = ''
        if(type == 1) {
          category.first = main_topic.categoryInfo.firstLevelId
          firstList.value = res.data
          if(main_topic.categoryInfo.firstLevelId) {
            getCategory(main_topic.categoryInfo.firstLevelId,2)
          }
        } else if(type == 2) {
          category.second = main_topic.categoryInfo.secondLevelId
          secondList.value = res.data
          if(main_topic.categoryInfo.secondLevelId) {
            getCategory(main_topic.categoryInfo.secondLevelId,3)
          }
        } else if(type == 3) {
          category.third = main_topic.categoryInfo.thirdLevelId
          thirdList.value = res.data
        }
      })
      
    }
    const close = async (flag) => {
      if(!flag) {
        const confirm = await root.$goDialog('确定要取消吗？')
        if(!confirm) return
      }
      sub_topic.value = []
      for(let key in main_topic) {
        main_topic[key] = ''
      }
      emit("change", false);
    };
    const confirm = async () => {
      if (!main_topic.img) {
        root.$message.warning("请上传图片");
        return;
      }
      if (!main_topic.title || !main_topic.title.trim()) {
        root.$message.warning("请输入首页标题");
        return;
      }
      if (main_topic.redirect_type == 1 && (!main_topic.redirect_url_inner_app || !main_topic.redirect_url_inner_app.trim())) {
        root.$message.warning("请输入跳转链接");
        return;
      }
      if(main_topic.redirect_type == 2 && main_topic.redirect_site && main_topic.redirect_site.length == 0) {
          root.$message.warning('请选择站点')
          return
        }
      if(main_topic.redirect_type == 2 && !category.third && !main_topic.search_text) {
          root.$message.warning('三级分类和搜索文本最少选择一个')
          return
        }
      main_topic.redirect_platform_category = category.third
      if (!main_topic.subtitle || !main_topic.subtitle.trim()) {
        root.$message.warning("请输入副标题");
        return;
      }
      if (sub_topic.value.length > 10) {
        root.$message.warning("最多支持增加10个模块");
        return;
      }
      if (sub_topic.value.length === 0) {
        root.$message.warning("最少增加1个模块");
        return;
      }
      let flag = ref(false)
        sub_topic.value.forEach(item =>{
        if(!item.good_url || !item.img || !item.title || !item.price || !item.site_id || !item.goods_id) {
          flag.value = true
        }
      })
      if(flag.value) {
        root.$message.warning("模块2里存在不完整信息，请重新确认");
        return;
      }
      try {
        const res = await root.$axios.post('/cms/topic/goods/save', {
          main_topic,
          sub_topic: sub_topic.value
        })
        if(res.code === 10000) {
          root.$message.success('保存成功')
          close(true);
        }
      } catch (error) {
        root.$message.error(error)
      }
    };
    const getImage = url => {
      main_topic.img = url
      main_topic.url = url
    }
    return {
      main_topic,
      sub_topic,
      siteList,
      firstList,
      secondList,
      thirdList,
      category,
      close,
      confirm,
      getImage,
      getCategory
    };
  },
});
</script>
<style lang="scss" scoped>
.ss-manage {
  z-index: 9;
  background-color: rgba(0, 0, 0, 0.6);
  right: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
  .ss-manage-box {
    text-align: left;
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    width: 100%;
    height: 86%;
    background-color: #ffffff;
    border-radius: 14px 14px 0 0;
    padding: 26px;
    display: flex;
    flex-direction: column;
    .close {
      cursor: pointer;
      font-size: 24px;
      position: absolute;
      right: 25px;
      top: 25px;
      color: $lightColor;
    }
    .title {
      font-size: 20px;
      font-weight: 500;
      color: $titleColor;
      line-height: 28px;
      flex-shrink: 0;
      height: 28px;
    }
    .ss-manage-box-content {
      display: flex;
      position: relative;
      flex: auto;
      overflow: hidden;
      &::after {
        position: absolute;
        content: " ";
        right: 50%;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: $lineColor;
      }
      & > div {
        width: 100%;
        padding-right: 54px;
        overflow-y: auto;
      }
      .el-input {
        ::v-deep .el-input__inner {
          height: 32px;
          line-height: 32px;
        }
      }
      .ss-manage-box-left {
        padding-top: 29px;
      }
      .ss-manage-box-right {
        padding-left: 51px;
      }
    }
    .ss-manage-footer {
      flex: auto;
      height: 68px;
      flex-shrink: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .el-button {
        width: 120px;
        height: 36px;
        margin-left: 24px;
      }
    }
  }
}
.icon {
  width: 32px;
  height: 32px;
  font-size: 18px;
  line-height: 32px;
  background-color: #a7abbd;
  color: #fff;
  border-radius: 100%;
  text-align: center;
}
.ss-manage-box-preview {
  .preview-content {
    width: 378px;
    height: 163px;
    background: #f4f5fb;
    display: flex;
    align-items: center;
    justify-content: center;
    .icon {
      font-size: 24px;
      line-height: 48px;
      width: 48px;
      height: 48px;
    }
  }
  .preview-subcontent {
    display: flex;
    flex-wrap: wrap;
    margin-top: 19px;
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      background: #f4f5fb;
      margin-right: 18px;
    }
  }
}
.block {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #9195a2;
  margin-top: 63px;
  .icon {
    margin-right: 12px;
  }
}
.upload-img {
  width: 536px;
  height: 300px;
  padding: 14px;
  background-color: #fafafa;
}
</style>