var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table"},[_c('header',{staticClass:"header"},[_c('switch-text',{attrs:{"left":'已启用',"right":'已停用'},model:{value:(_vm.flag),callback:function ($$v) {_vm.flag=$$v},expression:"flag"}}),_c('el-button',{staticStyle:{"border-radius":"16px"},attrs:{"type":"primary"},on:{"click":_vm.createEl}},[_vm._v("+新建")])],1),(_vm.flag)?_c('div',{staticStyle:{"height":"100%"}},[_c('DragTable',{attrs:{"list-query":_vm.list,"header":_vm.header,"sort":true},on:{"change":_vm.handle},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"title-style"},[_vm._v(_vm._s(row.title))])]}},{key:"updateTime",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.updateTime && _vm.$dayjs(row.updateTime).format('YYYY-MM-DD HH:mm') || ''))]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"status",class:{ active: row.state !== 2 }},[_vm._v(_vm._s(row.state === 2 ? "已启用" : "已停用"))])]}},{key:"control",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"control"},[_c('span',{class:{'active': row.state === 2},on:{"click":function($event){return _vm.open(row)}}},[_vm._v(_vm._s(row.state === 2 ? '停用': '启用'))]),_c('span',{on:{"click":function($event){return _vm.bannerEdit(row)}}},[_vm._v("编辑")]),_c('span',{staticClass:"gray",on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")])])]}}],null,false,598898596)})],1):_vm._e(),(!_vm.flag)?_c('DragTable',{attrs:{"list-query":_vm.list,"header":_vm.header,"sort":false},on:{"change":_vm.handle},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"title-style"},[_vm._v(_vm._s(row.title))])]}},{key:"updateTime",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.updateTime && _vm.$dayjs(row.updateTime).format('YYYY-MM-DD HH:mm') || ''))]}},{key:"status",fn:function(ref){
var row = ref.row;
return [_c('span',{staticClass:"status",class:{ active: row.state !== 2 }},[_vm._v(_vm._s(row.state === 2 ? "已启用" : "已停用"))])]}},{key:"control",fn:function(ref){
var row = ref.row;
return [_c('div',{staticClass:"control"},[_c('span',{class:{'active': row.state === 2},on:{"click":function($event){return _vm.open(row)}}},[_vm._v(_vm._s(row.state === 2 ? '停用': '启用'))]),_c('span',{on:{"click":function($event){return _vm.bannerEdit(row)}}},[_vm._v("编辑")]),_c('span',{staticClass:"gray",on:{"click":function($event){return _vm.del(row)}}},[_vm._v("删除")])])]}}],null,false,598898596)}):_vm._e(),_c('edit',{attrs:{"info":_vm.detail},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }